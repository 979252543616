import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthenticatedComponent} from './authenticated/authenticated.component';
import {EventsComponent} from './events/events.component';
import {NominationComponent} from './nomination/nomination.component';


const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        pathMatch: 'full'
    }, {
        path: 'authenticated',
        component: AuthenticatedComponent,
        children: [
            {
                path: 'events',
                component: EventsComponent

            },
            {
                path: 'nominations',
                component: NominationComponent

            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
