import {Component, OnInit} from '@angular/core';
import {HttpService} from '../Services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {Router} from '@angular/router';
import {isObject} from 'util';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [HttpService]
})
export class LoginComponent implements OnInit {
    loginData = {
        email: '',
        password: ''
    };

    constructor(
        public http: HttpService,
        public spinner: NgxSpinnerService,
        public toast: ToasterService,
        public router: Router
    ) {
    }

    ngOnInit() {
    }

    doLogin() {
        this.spinner.show();
        this.http.makeRequestPost(['login_user', this.loginData])
        .subscribe(
            (resp) => {
                this.spinner.hide();
                if (isObject(resp.data)) {
                    this.router.navigateByUrl('authenticated/events');
                } else {

                    this.toast.pop('error', 'Error', 'Could not log you in');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have working data connection');
            }
        );
    }

    doLoginEnter(evt) {
        if (evt.which == 13) {
            this.doLogin();
        }
    }

}
