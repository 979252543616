import {Component, OnInit} from '@angular/core';
import {HttpService} from '../Services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';

@Component({
    selector: 'app-nomination',
    templateUrl: './nomination.component.html',
    styleUrls: ['./nomination.component.scss'],
    providers: [HttpService]
})
export class NominationComponent implements OnInit {

    public allNominations = [];

    constructor(
        public http: HttpService,
        public spinner: NgxSpinnerService,
        public toast: ToasterService
    ) {
    }

    ngOnInit() {
        this.spinner.show();
        this.http.makeRequestPost(['get_all_nominations', {}])
        .subscribe(
            (resp) => {
                this.spinner.hide();

                this.allNominations = resp.data;
            },
            (error) => {
                this.spinner.hide();
            }
        );
    }

}
