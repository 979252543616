import {Component} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'KitchenAdmin';

    public config: ToasterConfig =
        new ToasterConfig({timeout: 5000, positionClass: 'toast-top-right'});
}
