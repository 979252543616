import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable()
export class HttpService {

    constructor(
        private newHttp: HttpClient
    ) {
    }

    makeRequestPost(dataToSend: Array<any> = []) {
        var data = {};
        data = dataToSend[1];

        // for (var i = 0; i < dataToSend.length; i++) {
        // 	if (i == 0) {
        // 		data['methodToCall'] = dataToSend[0];
        // 	} else {
        // 		data['x' + i] = dataToSend[i];
        // 	}
        // }
        return Observable.create(observer => {
            return this.newHttp.post('https://api.oursocialkitchen.co.za/' + dataToSend[0], data, {})
            .subscribe((response) => {
                observer.next(response);
                observer.complete();
            }, (error) => {
                observer.error(error);
                observer.complete();
            });


        });

    }


}
