import {Component, OnInit} from '@angular/core';
import {HttpService} from '../Services/HttpService';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToasterService} from 'angular2-toaster';
import {isObject} from 'util';
import {FileUploadService} from '../Services/FileUploadService';
import {DateAdapter} from '@angular/material';

import * as moment from 'moment';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    providers: [HttpService, FileUploadService]
})
export class EventsComponent implements OnInit {

    public allEvents = [];

    public eventToView;

    public eventToEdit;

    public itemToDelete;

    public showDeleteModalBool = false;

    public eventToAdd = {
        description: '',
        title: '',
        image_path: '',
        event_date: '',
        event_time: '',
        chef_name: '',
        chef_image_path: '',
        chef_website: '',
        chef_facebook: '',
        chef_twitter: '',
        chef_instagram: '',
        video_link: '',
        basket_link: '',
        products: [],
        ingredients: [],
        width: '',
        height: '',
        method: '',
        ingredientsText: ''

    };

    public showAddEvent = false;

    constructor(
        public http: HttpService,
        public spinner: NgxSpinnerService,
        public toast: ToasterService,
        public fileUpload: FileUploadService,
        public dateThing: DateAdapter<any>
    ) {
        this.dateThing.setLocale('zu-ZA');
    }

    ngOnInit() {
        this.spinner.show();
        this.http.makeRequestPost(['get_events_admin', {}])
        .subscribe(
            (resp) => {
                this.spinner.hide();
                if (isObject(resp.data)) {
                    this.allEvents = resp.data;

                    for (var i = 0; i < this.allEvents.length; i++) {
                        this.allEvents[i].date_to_show = this.allEvents[i].event_date;
                    }

                    for (var i = 0; i < this.allEvents.length; i++) {
                        // this.allEvents[i].event_date = new Date(this.allEvents[i].event_date);
                        this.allEvents[i].event_date = moment(this.allEvents[i].event_date).format('YYYY-MM-DD');


                    }
                } else {
                    this.toast.pop('error', 'Error', 'Could not get all the events');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    viewEventDetails(event) {

        this.eventToView = event;
        // this.eventToView.event_date = new Date(this.eventToView.event_date);
    }

    closeModal() {

        this.eventToView = undefined;
        this.eventToEdit = undefined;
        this.eventToAdd = {
            description: '',
            title: '',
            image_path: '',
            event_date: '',
            event_time: '',
            chef_name: '',
            chef_image_path: '',
            chef_website: '',
            chef_facebook: '',
            chef_twitter: '',
            chef_instagram: '',
            video_link: '',
            basket_link: '',
            products: [],
            ingredients: [],
            width: '',
            height: '',
            method: '',
            ingredientsText: ''

        };
    }

    setEventToEdit(event) {
        this.eventToEdit = event;
        console.log(event.ingredients);
        this.eventToEdit.ingredientsText = '';
        for (var i = 0; i < this.eventToEdit.ingredients.length; i++) {
            //code
            console.log(this.eventToEdit.ingredients[i].ingredient_name);
            this.eventToEdit.ingredientsText += this.eventToEdit.ingredients[i].ingredient_name + '\n';
        }

    }

    removeIngredient(objectToRemoveFrom, ingredient) {
        for (var i = 0; i < objectToRemoveFrom.ingredients.length; i++) {
            if (objectToRemoveFrom.ingredients[i].id == ingredient.id) {
                objectToRemoveFrom.ingredients.splice(i, 1);
            }
        }
    }

    addIngredient(objectToAddTo) {
        objectToAddTo.ingredients.push(
            {
                ingredient_name: '',
                event_id: objectToAddTo.event_id
            }
        );
    }

    removeProduct(objectToRemoveFrom, product) {
        for (var i = 0; i < objectToRemoveFrom.ingredients.length; i++) {
            if (objectToRemoveFrom.products[i].id == product.id) {
                objectToRemoveFrom.products.splice(i, 1);
            }
        }
    }

    addProduct(objectToAddTo) {
        objectToAddTo.products.push({
            product_name: '',
            product_link: '',
            product_image_path: '',
            product_description: '',
            event_id: objectToAddTo.event_id
        });
    }

    onDropProduct(event, product, objectToAddTo) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event.dataTransfer.files);
        this.uploadFileProduct(event.dataTransfer.files, product, objectToAddTo);
        // your code goes here after droping files or any
    }

    onDragOverProduct(evt, product, objectToAddTo) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    onDragLeaveProduct(evt, product, objectToAddTo,) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    fileChangeProduct(event, product, objectToAddTo) {
        console.log(event.target.files);
        this.uploadFileProduct(event.target.files, product, objectToAddTo);

    }

    uploadFileProduct(file, product, objectToAddTo) {
        console.log(file);
        this.spinner.show();

        this.fileUpload.uploadFile('upload_image', file, {})
        .subscribe(
            (response) => {
                console.log(response);
                setTimeout(() => {
                    for (var i = 0; i < objectToAddTo.products.length; i++) {
                        if (objectToAddTo.products[i].id == product.id) {
                            objectToAddTo.products[i].product_image_path = response.data;
                        }
                    }
                }, 1000);

                this.spinner.hide();
            },
            (error) => {
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                this.spinner.hide();
            }
        );
    }


    onDropEventImage(event, eventDetails) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event.dataTransfer.files);
        this.uploadFileEventImage(event.dataTransfer.files, eventDetails);
        // your code goes here after droping files or any
    }

    onDragOverEventImage(evt, eventDetails) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    onDragLeaveEventImage(evt, eventDetails) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    fileChangeEventImage(event, eventDetails) {
        console.log(event.target.files);
        this.uploadFileEventImage(event.target.files, eventDetails);

    }

    uploadFileEventImage(file, eventDetails) {
        console.log(file);
        this.spinner.show();

        this.fileUpload.uploadFile('upload_image', file, {})
        .subscribe(
            (response) => {
                console.log(response);
                setTimeout(() => {
                    eventDetails.image_path = response.data;
                }, 1000);

                this.spinner.hide();
            },
            (error) => {
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                this.spinner.hide();
            }
        );
    }

    onDropChefImage(event, eventDetails) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event.dataTransfer.files);
        this.uploadFileChefImage(event.dataTransfer.files, eventDetails);
        // your code goes here after droping files or any
    }

    onDragOverChefImage(evt, eventDetails) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    onDragLeaveChefImage(evt, eventDetails) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    fileChangeChefImage(event, eventDetails) {
        console.log(event.target.files);
        this.uploadFileChefImage(event.target.files, eventDetails);

    }

    uploadFileChefImage(file, eventDetails) {
        console.log(file);
        this.spinner.show();

        this.fileUpload.uploadFile('upload_image', file, {})
        .subscribe(
            (response) => {
                console.log(response);
                setTimeout(() => {
                    eventDetails.chef_image_path = response.data;
                }, 1000);

                this.spinner.hide();
            },
            (error) => {
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
                this.spinner.hide();
            }
        );
    }

    updateEvent() {
        this.eventToEdit.event_date = moment(this.eventToEdit.event_date).format('YYYY-MM-DD');


        this.eventToEdit.ingredients = this.eventToEdit.ingredientsText.split('\n');
        this.eventToEdit.ingredients = this.eventToEdit.ingredients.map((s) => {
            return {ingredient_name: s};
        });
        this.spinner.show();
        this.http.makeRequestPost(['update_event', this.eventToEdit])
        .subscribe(
            (resp) => {
                if (resp.data) {
                    this.spinner.hide();
                    if (isObject(resp.data)) {
                        for (var i = 0; i < this.allEvents.length; i++) {
                            if (this.eventToEdit.id == this.allEvents[i].id) {
                                this.allEvents[i] = resp.data;
                            }
                        }
                        this.toast.pop('success', 'Success', 'The event has been updated');
                        this.eventToEdit = undefined;
                    } else {

                        this.toast.pop('error', 'Error', 'Could not update the event');
                    }
                } else {
                    this.toast.pop('error', 'Error', 'Could not update the event');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    addEvent() {
        this.eventToAdd.event_date = moment(this.eventToAdd.event_date).format('YYYY-MM-DD');


        this.eventToAdd.ingredients = this.eventToAdd.ingredientsText.split('\n');
        this.eventToAdd.ingredients = this.eventToAdd.ingredients.map((s) => {
            return {ingredient_name: s};
        });
        console.log(this.eventToAdd.ingredients);
        this.spinner.show();
        this.http.makeRequestPost(['add_event', this.eventToAdd])
        .subscribe(
            (resp) => {
                if (resp.data) {
                    this.spinner.hide();
                    if (isObject(resp.data)) {
                        this.allEvents.unshift(resp.data);
                        this.toast.pop('success', 'Success', 'The event has been updated');
                        this.eventToAdd = {
                            description: '',
                            title: '',
                            image_path: '',
                            event_date: '',
                            event_time: '',
                            chef_name: '',
                            chef_image_path: '',
                            chef_website: '',
                            chef_facebook: '',
                            chef_twitter: '',
                            chef_instagram: '',
                            video_link: '',
                            basket_link: '',
                            products: [],
                            ingredients: [],
                            width: '',
                            height: '',
                            method: '',
                            ingredientsText: ''

                        };
                        this.showAddEvent = false;
                    } else {

                        this.toast.pop('error', 'Error', 'Could not update the event');
                    }
                } else {
                    this.toast.pop('error', 'Error', 'Could not update the event');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    deleteEvent() {
        console.log(this.itemToDelete);
        this.spinner.show();
        this.http.makeRequestPost(['delete_event', this.itemToDelete])
        .subscribe(
            (resp) => {
                this.spinner.hide();
                if (resp.data) {
                    if (isObject(resp.data)) {
                        this.toast.pop('success', 'Success', 'The event has been deleted');
                        for (var i = 0; i < this.allEvents.length; i++) {
                            if (this.allEvents[i].id == this.itemToDelete.id) {
                                this.allEvents[i].active = 0;
                            }
                        }

                        this.itemToDelete = undefined;
                        this.showDeleteModalBool = false;

                    } else {
                        this.toast.pop('error', 'Error', 'Could not delete the event');
                    }
                } else {
                    this.toast.pop('error', 'Error', 'Could not delete the event');
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.pop('error', 'Error', 'Please make sure you have a working data connection');
            }
        );
    }

    getDate(date) {
        return new Date(date);
    }

}
