import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToasterModule} from 'angular2-toaster';
import {LoginComponent} from './login/login.component';
import {FormsModule} from '@angular/forms';
import {HttpService} from './Services/HttpService';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthenticatedComponent} from './authenticated/authenticated.component';
import {EventsComponent} from './events/events.component';
import {MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import { NominationComponent } from './nomination/nomination.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AuthenticatedComponent,
        EventsComponent,
        NominationComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxSpinnerModule,
        ToasterModule.forRoot(),
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule
    ],
    providers: [
        HttpService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
