import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-authenticated',
    templateUrl: './authenticated.component.html',
    styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {

    constructor(
        public router: Router
    ) {
    }

    ngOnInit() {
    }

    openEvents() {
        this.router.navigateByUrl('authenticated/events');
    }

    openNominations() {
        this.router.navigateByUrl('authenticated/nominations');
    }

}
